import * as i0 from '@angular/core';
import { ChangeDetectorRef, Component, ChangeDetectionStrategy, Optional, Self, Inject, ViewChild, Input, NgModule } from '@angular/core';
import * as i2 from '@angular/forms';
import { NgControl, FormsModule } from '@angular/forms';
import { AbstractTuiNullableControl, TUI_DEFAULT_IDENTITY_MATCHER, tuiAsFocusableItemAccessor, tuiAsControl, TuiFocusableModule, TuiFocusedModule } from '@taiga-ui/cdk';
import { TUI_MODE, MODE_PROVIDER } from '@taiga-ui/core';
import * as i1 from '@taiga-ui/kit/components/radio';
import { TUI_RADIO_OPTIONS, TuiRadioComponent, TuiRadioModule } from '@taiga-ui/kit/components/radio';
import * as i3 from 'rxjs';
import { CommonModule } from '@angular/common';
const _c0 = ["*"];
class TuiRadioLabeledComponent extends AbstractTuiNullableControl {
  constructor(control, cdr, mode$, options) {
    super(control, cdr);
    this.mode$ = mode$;
    this.options = options;
    this.size = this.options.size;
    this.identityMatcher = TUI_DEFAULT_IDENTITY_MATCHER;
    this.pseudoDisabled = false;
  }
  get nativeFocusableElement() {
    var _a, _b;
    return (_b = (_a = this.radio) === null || _a === void 0 ? void 0 : _a.nativeFocusableElement) !== null && _b !== void 0 ? _b : null;
  }
  get focused() {
    return !!this.radio && this.radio.focused;
  }
  get computedDisabled() {
    return this.disabled || this.pseudoDisabled;
  }
  onFocused(focused) {
    this.updateFocused(focused);
  }
  /** @deprecated use 'value' setter */
  onModelChange(value) {
    this.value = value;
  }
}
TuiRadioLabeledComponent.ɵfac = function TuiRadioLabeledComponent_Factory(t) {
  return new (t || TuiRadioLabeledComponent)(i0.ɵɵdirectiveInject(NgControl, 10), i0.ɵɵdirectiveInject(ChangeDetectorRef), i0.ɵɵdirectiveInject(TUI_MODE), i0.ɵɵdirectiveInject(TUI_RADIO_OPTIONS));
};
TuiRadioLabeledComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TuiRadioLabeledComponent,
  selectors: [["tui-radio-labeled"]],
  viewQuery: function TuiRadioLabeledComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(TuiRadioComponent, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.radio = _t.first);
    }
  },
  hostVars: 1,
  hostBindings: function TuiRadioLabeledComponent_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("$.data-mode.attr", function TuiRadioLabeledComponent___data_mode_attr_HostBindingHandler() {
        return ctx.mode$;
      });
    }
    if (rf & 2) {
      i0.ɵɵattribute("data-size", ctx.size);
    }
  },
  inputs: {
    item: "item",
    size: "size",
    identityMatcher: "identityMatcher",
    pseudoDisabled: "pseudoDisabled"
  },
  features: [i0.ɵɵProvidersFeature([tuiAsFocusableItemAccessor(TuiRadioLabeledComponent), tuiAsControl(TuiRadioLabeledComponent), MODE_PROVIDER]), i0.ɵɵInheritDefinitionFeature],
  ngContentSelectors: _c0,
  decls: 4,
  vars: 15,
  consts: [[1, "t-wrapper"], [3, "ngModelChange", "focusedChange", "focusable", "identityMatcher", "item", "name", "nativeId", "pseudoActive", "pseudoDisabled", "pseudoFocus", "pseudoHover", "pseudoInvalid", "readOnly", "size", "ngModel"], [1, "t-content"]],
  template: function TuiRadioLabeledComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵelementStart(0, "label", 0)(1, "tui-radio", 1);
      i0.ɵɵtwoWayListener("ngModelChange", function TuiRadioLabeledComponent_Template_tui_radio_ngModelChange_1_listener($event) {
        i0.ɵɵtwoWayBindingSet(ctx.value, $event) || (ctx.value = $event);
        return $event;
      });
      i0.ɵɵlistener("focusedChange", function TuiRadioLabeledComponent_Template_tui_radio_focusedChange_1_listener($event) {
        return ctx.onFocused($event);
      });
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(2, "div", 2);
      i0.ɵɵprojection(3);
      i0.ɵɵelementEnd()();
    }
    if (rf & 2) {
      i0.ɵɵadvance();
      i0.ɵɵproperty("focusable", ctx.focusable)("identityMatcher", ctx.identityMatcher)("item", ctx.item)("name", ctx.computedName || "")("nativeId", ctx.nativeId)("pseudoActive", ctx.pseudoActive)("pseudoDisabled", ctx.computedDisabled)("pseudoFocus", ctx.pseudoFocus)("pseudoHover", ctx.pseudoHover)("pseudoInvalid", ctx.computedInvalid)("readOnly", ctx.readOnly)("size", ctx.size);
      i0.ɵɵtwoWayProperty("ngModel", ctx.value);
      i0.ɵɵadvance();
      i0.ɵɵclassProp("t-content_disabled", ctx.computedDisabled);
    }
  },
  dependencies: [i1.TuiRadioComponent, i2.NgControlStatus, i2.NgModel],
  styles: ["[_nghost-%COMP%]{position:relative;display:block;font:var(--tui-font-text-s);line-height:1rem}._readonly[_nghost-%COMP%]{pointer-events:none}[data-size=l][_nghost-%COMP%]{line-height:1.5rem}.t-wrapper[_ngcontent-%COMP%]{display:inline-flex;max-width:100%;cursor:pointer;vertical-align:top;align-items:inherit}._disabled[_nghost-%COMP%]   .t-wrapper[_ngcontent-%COMP%]{cursor:default}[data-mode=onDark][_nghost-%COMP%]   .t-wrapper[_ngcontent-%COMP%]{color:var(--tui-text-01-night)}.t-content[_ngcontent-%COMP%]{word-wrap:break-word;min-width:0;line-height:inherit}.t-content_disabled[_ngcontent-%COMP%]{opacity:var(--tui-disabled-opacity)}[data-size=m][_nghost-%COMP%]   .t-content[_ngcontent-%COMP%]{margin-left:.5rem}[data-size=l][_nghost-%COMP%]   .t-content[_ngcontent-%COMP%]{font:var(--tui-font-text-m);margin-left:.75rem;line-height:inherit}"],
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiRadioLabeledComponent, [{
    type: Component,
    args: [{
      selector: 'tui-radio-labeled',
      templateUrl: './radio-labeled.template.html',
      styleUrls: ['./radio-labeled.style.less'],
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [tuiAsFocusableItemAccessor(TuiRadioLabeledComponent), tuiAsControl(TuiRadioLabeledComponent), MODE_PROVIDER],
      host: {
        '($.data-mode.attr)': 'mode$',
        '[attr.data-size]': 'size'
      }
    }]
  }], function () {
    return [{
      type: i2.NgControl,
      decorators: [{
        type: Optional
      }, {
        type: Self
      }, {
        type: Inject,
        args: [NgControl]
      }]
    }, {
      type: i0.ChangeDetectorRef,
      decorators: [{
        type: Inject,
        args: [ChangeDetectorRef]
      }]
    }, {
      type: i3.Observable,
      decorators: [{
        type: Inject,
        args: [TUI_MODE]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_RADIO_OPTIONS]
      }]
    }];
  }, {
    radio: [{
      type: ViewChild,
      args: [TuiRadioComponent]
    }],
    item: [{
      type: Input
    }],
    size: [{
      type: Input
    }],
    identityMatcher: [{
      type: Input
    }],
    pseudoDisabled: [{
      type: Input
    }]
  });
})();
class TuiRadioLabeledModule {}
TuiRadioLabeledModule.ɵfac = function TuiRadioLabeledModule_Factory(t) {
  return new (t || TuiRadioLabeledModule)();
};
TuiRadioLabeledModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiRadioLabeledModule
});
TuiRadioLabeledModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule, FormsModule, TuiFocusableModule, TuiFocusedModule, TuiRadioModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiRadioLabeledModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, FormsModule, TuiFocusableModule, TuiFocusedModule, TuiRadioModule],
      declarations: [TuiRadioLabeledComponent],
      exports: [TuiRadioLabeledComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiRadioLabeledComponent, TuiRadioLabeledModule };
